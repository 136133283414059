export const useInputImageRenderer = (inputEl: HTMLInputElement, callback: (result: string | ArrayBuffer | null) => void) => {
  const inputImageRenderer = () => {
    const { files } = inputEl

    if (files) {
      const [file] = files
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          callback(reader.result)
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  return {
    inputImageRenderer,
  }
}

export const _ = null
